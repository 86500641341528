import theme from '@ifca-root/react-component/src/assets/theme';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import {
	CreditCardType,
	PaymentClass,
	useGetMenuXDebtorListLazyQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetMenuXInhouseListQuery,
	useGetOfficerLazyQuery,
	useGetRoundingPolicyLazyQuery,
	useGetStaffLazyQuery,
} from 'generated/graphql';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { IAction } from 'helpers/model';
import { amtStr } from 'helpers/numFormatter';
import React, { Reducer, useEffect, useReducer, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import SignatureCanvas from 'react-signature-canvas';
import {
	PaymentDialogProps,
	ReasonProps,
	ReducerProps,
	SignatureProps,
} from './DialogInterface';
import { SearchField } from './SearchDialogComponent';
import { openDrawer } from '../../containers/OutletModule/Home/TableSubmenu/BillSettlement/BillReceipt/BillReceiptFunctions';

export const PaymentDialogComponent = (props: PaymentDialogProps) => {
	const {
		selectedRoom,
		setSelectedRoom,
		selectedNonGuest,
		setSelectedNonGuest,
		selectedFolio,
		setSelectedFolio,
		openDialog,
		paymentType,
		setOpenDialog,
		getDefaultPayment,
		getOutlet,
		control,
		errors,
		register,
		hotelID,
		integrateCityLedger,
		outletID,
		defaultRoom,
		setDefaultRoom,
		defaultNonGuest,
		setDefaultNonGuest,
		defaultFolio,
		setDefaultFolio,
		defaultDebtor,
		setDefaultDebtor,
		defaultOfficer,
		setDefaultOfficer,
		defaultStaff,
		setDefaultStaff,
		selectedOfficer,
		selectedStaff,
		setSelectedOfficer,
		setSelectedStaff,
		selectedDebtor,
		setSelectedDebtor,
		selectedOrder,
		setHotelGuestInfo,
		total,
		setValue,
		getValue,
		watch,
		radioError,
		setRadioError,
		selectedPaymentClass,
		handleSubmit,
		openReason,
		setOpenReason,
		openSignature,
		onSubmit,
		setOpenSignature,
		setChangeAmount,
		setReceivedAmount,
		minVal,
		setMinVal,
		receivedAmount,
		reset,
		mode,
		loading1,
		loading2,
		loading3,
		lastArray,
		selectedCredit,
		setSelectedCredit,
	} = props;
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	// state
	const [filterCond, setFilterCond] = useState({
		room: false,
		nonGuest: false,
		folio: false,
		debtor: false,
		officer: false,
		staff: false,
	});

	const [tempState, setTempState] = useState('');
	// const [minVal, setMinVal] = useState(0);

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	//reducer-----------------------------------------
	//reducer-----------------------------------------
	const initialState = {
		cardDropDown: false,
		cashAmount: false,
		hotelSearchField: false,
		nonGuestSearchField: false,
		folioSearchField: false,
		debtorSearchField: false,
		officerSearchField: false,
		staffSearchField: false,
		voucher: false,
		others: false,
		bankTT: false,
		member: false,
		onlinePayment: false,
		skipperAccount: false,
		cheque: false,
	};

	const reducer: Reducer<ReducerProps, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const [disablePaymentClass, setDisablePaymentClass] = useState('');
	//reducer-----------------------------------------
	//reducer-----------------------------------------

	//hotel stuff query idk i just make the component @edit add for staff and offier------------------------
	//hotel stuff query idk i just make the component------------------------

	const getRoundedAmt = (amount, round) => {
		let roundingAmt = amount - Math.floor(amount);

		let roundedAmt = Math.round((roundingAmt + Number.EPSILON) * 100) / 100;

		let roundingMulti = Math.round(
			(roundedAmt * 100) / ((round ?? 0.05) * 100),
		);
		let finalAmt = Math.floor(amount) + (round ?? 0.05) * roundingMulti;

		return finalAmt;
	};

	const [
		loadRoundingPolicy,
		{
			called: getRoundingPolicyCalled,
			loading: getRoundingPolicyLoading,
			data: { getRoundingPolicy } = { getRoundingPolicy: [] },
			refetch: chargesRefetch,
		},
	] = useGetRoundingPolicyLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getRoundingPolicy }) => {
			setReceivedAmount(
				getRoundingPolicy[0]?.inUse === true
					? getRoundedAmt(balanceT, getRoundingPolicy[0]?.smallestDenomination)
					: balanceT,
			);
			setValue(
				'receivedAmount',
				getRoundingPolicy[0]?.inUse === true
					? getRoundedAmt(balanceT, getRoundingPolicy[0]?.smallestDenomination)
					: balanceT,
			);
			setMinVal(
				getRoundingPolicy[0]?.inUse === true
					? getRoundedAmt(balanceT, getRoundingPolicy[0]?.smallestDenomination)
					: balanceT,
			);
		},
	});

	// const [
	// 	loadRoundingPolicy,
	// 	{
	// 		called: getRoundingPolicyCalled,
	// 		loading: getRoundingPolicyLoading,
	// 		data: { getRoundingPolicy } = { getRoundingPolicy: [] },
	// 	},
	// ] = useGetRoundingPolicyLazyQuery({
	// 	fetchPolicy: 'network-only',
	// 	onCompleted: ({ getRoundingPolicy }) => {
	// 		setValue(
	// 			'receivedAmount',
	// 			getRoundedAmt(total, getRoundingPolicy[0]?.smallestDenomination),
	// 		);
	// 		setMinVal(
	// 			getRoundedAmt(total, getRoundingPolicy[0]?.smallestDenomination),
	// 		);
	// 	},
	// });

	const {
		called: menuXHouseCalled,
		loading: MenuXInhouseListLoading,
		data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
	} = useGetMenuXInhouseListQuery({
		skip: !hotelID || !outletID,
		variables: {
			hotelID,
			outletID,
		},
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXInhouseList }) => {
			let defaultOrder = selectedOrder;
			if (paymentType === 'voidPayment') {
				defaultOrder = selectedOrder?.order;
			}
			if (defaultOrder?.patronAccountID !== null) {
				let defaultGuest = getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
					x => x?.RegistrationID === defaultOrder?.patronAccountID,
				)[0];
				if (defaultGuest?.GuestType === 'RoomGuest') {
					setDefaultRoom(defaultGuest);
				} else if (defaultGuest?.GuestType === 'NonGuest') {
					setDefaultNonGuest(defaultGuest);
				} else if (defaultGuest?.GuestType === 'Suspend Folio') {
					setDefaultFolio(defaultGuest);
				}
			}
		},
	});

	// const [
	// 	loadMenuXInhouseList,
	// 	{
	// 		called: menuXHouseCalled,
	// 		loading: MenuXInhouseListLoading,
	// 		data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
	// 	},
	// ] = useGetMenuXInhouseListLazyQuery({
	// 	fetchPolicy: 'network-only',
	// 	onCompleted: ({ getMenuXInhouseList }) => {
	// 		let defaultOrder = selectedOrder;
	// 		if (paymentType === 'voidPayment') {
	// 			defaultOrder = selectedOrder?.order;
	// 		}
	// 		if (defaultOrder?.patronAccountID !== null) {
	// 			let defaultGuest = getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
	// 				x => x?.RegistrationID === defaultOrder?.patronAccountID,
	// 			)[0];
	// 			if (defaultGuest?.GuestType === 'RoomGuest') {
	// 				setDefaultRoom(defaultGuest);
	// 			} else {
	// 				setDefaultNonGuest(defaultGuest);
	// 			}
	// 		}
	// 	},
	// });

	const [
		loadMenuXDebtorList,
		{
			called: menuXDebtorCalled,
			loading: MenuXDebtorListLoading,
			data: { getMenuXDebtorList } = { getMenuXDebtorList: [] },
		},
	] = useGetMenuXDebtorListLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXDebtorList }) => {
			// let selectedOrder;
			// if (paymentType === 'voidPayment') {
			// selectedOrder = selectedOrder?.order;
			let defaultOrder = selectedOrder;
			if (paymentType === 'voidPayment') {
				defaultOrder = selectedOrder?.order;
			}
			if (defaultOrder?.patronAccountID !== null) {
				setDefaultDebtor(
					getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
						x => x?.RegistrationID === defaultOrder?.registrationID,
					)[0],
				);
			}
			// }
		},
	});

	const [
		loadOfficer,
		{ loading: loadingOfficer, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOfficer }) => {
			let defaultOrder = selectedOrder;
			if (paymentType === 'voidPayment') {
				defaultOrder = selectedOrder?.order;
			}
			if (defaultOrder?.patronAccountID !== null) {
				setDefaultOfficer(
					getOfficer?.filter(x => x?.ID === defaultOrder?.patronAccountID)[0],
				);
			}
		},
	});

	const [
		loadStaff,
		{ loading: loadingStaff, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getStaff }) => {
			let defaultOrder = selectedOrder;
			if (paymentType === 'voidPayment') {
				defaultOrder = selectedOrder?.order;
			}
			if (defaultOrder?.patronAccountID !== null) {
				setDefaultStaff(
					getStaff?.filter(x => x?.ID === defaultOrder?.patronAccountID)[0],
				);
			}
		},
	});

	//hotel stuff query idk i just make the component------------------------
	//hotel stuff query idk i just make the component------------------------

	const handleRadio = v => {
		// setHotelTempState('');
		// setDebtorTempState('');
		// setStaffTempState('');
		// setOfficerTempState('');
		setTempState('');
		const defaultState = [
			{
				type: PaymentClass.Credit,
				name: 'cardDropDown',
			},
			{
				type: PaymentClass.Cash,
				name: 'cashAmount',
			},
			{
				type: PaymentClass.Room,
				name: 'hotelSearchField',
			},
			{
				type: PaymentClass.NonGuest,
				name: 'nonGuestSearchField',
			},
			{
				type: PaymentClass.SuspendFolio,
				name: 'folioSearchField',
			},
			{
				type: PaymentClass.Debtor,
				name: 'debtorSearchField',
			},
			{
				type: PaymentClass.Officer,
				name: 'officerSearchField',
			},
			{
				type: PaymentClass.Staff,
				name: 'staffSearchField',
			},
			{
				type: PaymentClass.Voucher,
				name: 'voucher',
			},
			{
				type: PaymentClass.Others,
				name: 'others',
			},
			{
				type: PaymentClass.BankTt,
				name: 'bankTT',
			},
			{
				type: PaymentClass.Member,
				name: 'member',
			},
			{
				type: PaymentClass.Online,
				name: 'onlinePayment',
			},
			{
				type: PaymentClass.Skipper,
				name: 'skipperAccount',
			},
			{
				type: PaymentClass.Cheque,
				name: 'cheque',
			},
		];

		defaultState?.map(d => {
			if (d?.type === v?.paymentClass) {
				if (
					v?.paymentClass === PaymentClass.Cash &&
					getRoundingPolicy?.length
				) {
					loadRoundingPolicy();
				}
				dispatch({
					type: d?.name,
					payload: true,
				});
			} else {
				// setReceivedAmount(0);
				dispatch({
					type: d?.name,
					payload: false,
				});
			}
		});
	};
	const handleCallLazy = v => {
		if (
			(v?.paymentClass === PaymentClass.Debtor ||
				v?.paymentClass === PaymentClass.Credit) &&
			integrateCityLedger
		) {
			setFilterCond({
				debtor: true,
				officer: false,
				room: false,
				nonGuest: false,
				staff: false,
				folio: false,
			});
			if (!MenuXDebtorListLoading && getMenuXDebtorList?.length === 0) {
				loadMenuXDebtorList({
					variables: {
						hotelID: hotelID,
						outletID: outletID,
					},
				});
			}
		} else if (v?.paymentClass === PaymentClass.Room && hotelID) {
			setFilterCond({
				room: true,
				nonGuest: false,
				officer: false,
				debtor: false,
				staff: false,
				folio: false,
			});
			// if (!MenuXInhouseListLoading && getMenuXInhouseList?.length === 0) {
			// 	loadMenuXInhouseList({
			// 		variables: {
			// 			hotelID: hotelID,
			// 			outletID: outletID,
			// 		},
			// 	});
			// }
		} else if (v?.paymentClass === PaymentClass.NonGuest && hotelID) {
			setFilterCond({
				room: false,
				nonGuest: true,
				officer: false,
				debtor: false,
				staff: false,
				folio: false,
			});
			// if (!MenuXInhouseListLoading && getMenuXInhouseList?.length === 0) {
			// 	loadMenuXInhouseList({
			// 		variables: {
			// 			hotelID: hotelID,
			// 			outletID: outletID,
			// 		},
			// 	});
			// }
		} else if (v?.paymentClass === PaymentClass.SuspendFolio && hotelID) {
			setFilterCond({
				room: false,
				nonGuest: false,
				officer: false,
				debtor: false,
				staff: false,
				folio: true,
			});
		} else if (v?.paymentClass === PaymentClass.Officer) {
			setFilterCond({
				officer: true,
				debtor: false,
				room: false,
				nonGuest: false,
				staff: false,
				folio: false,
			});
			if (!loadingOfficer && getOfficer?.length === 0) {
				loadOfficer({
					variables: {
						isActive: true,
					},
				});
			}
		} else if (v?.paymentClass === PaymentClass.Staff) {
			setFilterCond({
				officer: false,
				debtor: false,
				room: false,
				nonGuest: false,
				staff: true,
				folio: false,
			});
			if (!loadingStaff && getStaff?.length === 0) {
				loadStaff({
					variables: {
						isActive: true,
					},
				});
			}
		}
	};

	const creditCardText = data => {
		let credClass;
		setSelectedCredit(watch('cardType'));
		if (
			data === CreditCardType.AmericanExpressCard ||
			data === 'American Express Card'
		) {
			credClass = 'American Express Card';
		} else if (data === CreditCardType.DinersCard || data === 'Diners Card') {
			credClass = 'Diners Card';
		} else if (data === CreditCardType.JcbCard || data === 'JCB Card') {
			credClass = 'JCB Card';
		} else if (data === CreditCardType.Mastercard || data === 'Master Card') {
			credClass = 'Master Card';
		} else if (data === CreditCardType.UnionpayCard || data === 'UNION PAY') {
			credClass = 'UNION PAY';
		} else if (data === CreditCardType.VisaCard || data === 'Visa Card') {
			credClass = 'Visa Card';
		} else if (data === CreditCardType.DebitCard || data === 'Debit Card') {
			credClass = 'Debit Card';
		} else if (data === CreditCardType.Ewallet || data === 'E-Wallet') {
			credClass = 'E-Wallet';
		}
		return credClass;
	};

	const creditCardTypeEnum = Object.values(CreditCardType);

	const CardDropDownDownField = () => {
		const cardDebtors = getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
			x => x?.GuestStatus === 'CARD',
		);

		if (!MenuXDebtorListLoading && cardDebtors?.length > 0) {
			return (
				<TextField select>
					{cardDebtors?.map((el: any, index: number) => (
						<MenuItem key={index} value={el}>
							<span className="text-noflow">
								{creditCardText(el?.GuestType)}
							</span>
						</MenuItem>
					))}
				</TextField>
			);
		} else {
			return (
				<TextField select>
					{creditCardTypeEnum?.map((el: any, index: number) => (
						<MenuItem key={index} value={el}>
							<span className="text-noflow">{creditCardText(el)}</span>
						</MenuItem>
					))}
				</TextField>
			);
		}
	};

	useEffect(() => {
		if (filterCond.room) {
			setOriginalListing(getMenuXInhouseList?.data?.MenuXInhouseList);
		}
	}, [getMenuXInhouseList, filterCond.room]);

	useEffect(() => {
		if (filterCond.nonGuest) {
			setOriginalListing(getMenuXInhouseList?.data?.MenuXInhouseList);
		}
	}, [getMenuXInhouseList, filterCond.nonGuest]);

	useEffect(() => {
		if (filterCond.folio) {
			setOriginalListing(getMenuXInhouseList?.data?.MenuXInhouseList);
		}
	}, [getMenuXInhouseList, filterCond.folio]);

	useEffect(() => {
		if (filterCond.debtor) {
			setOriginalListing(getMenuXDebtorList?.data?.MenuXDebtorList);
		}
	}, [getMenuXDebtorList, filterCond.debtor]);

	useEffect(() => {
		if (getOfficer?.length > 0 && getOfficer && filterCond.officer) {
			setOriginalListing(getOfficer);
		}
	}, [getOfficer, filterCond.officer]);

	useEffect(() => {
		if (getStaff?.length > 0 && getStaff && filterCond.staff) {
			setOriginalListing(getStaff);
		}
	}, [getStaff, filterCond.staff]);

	const disableButton = () => {
		let p = selectedPaymentClass();
		if (p === PaymentClass.Cash) {
			return (
				watch(`defaultPaymentID`) === undefined ||
				(state?.cashAmount && receivedAmount - minVal < 0)
			);
		} else if (p === PaymentClass.Credit) {
			return (
				watch(`defaultPaymentID`) === undefined ||
				watch('cardReference') === undefined ||
				watch(`cardType`) === undefined
			);
		} else if (p === PaymentClass.Room) {
			return (
				Object?.values(selectedRoom ?? {})?.length <= 0 &&
				Object?.values(defaultRoom ?? {})?.length <= 0
			);
		} else if (p === PaymentClass.NonGuest) {
			return (
				Object?.values(selectedNonGuest ?? {})?.length <= 0 &&
				Object?.values(defaultNonGuest ?? {})?.length <= 0
			);
		} else if (p === PaymentClass.SuspendFolio) {
			return (
				Object?.values(selectedFolio ?? {})?.length <= 0 &&
				Object?.values(defaultFolio ?? {})?.length <= 0
			);
		} else if (p === PaymentClass.Debtor) {
			return (
				Object?.values(selectedDebtor ?? {})?.length <= 0 &&
				Object?.values(defaultDebtor ?? {})?.length <= 0
			);
		} else if (p === PaymentClass.Officer) {
			return (
				Object?.values(selectedOfficer ?? {})?.length <= 0 &&
				Object?.values(defaultOfficer ?? {})?.length <= 0
			);
		} else if (p === PaymentClass.Staff) {
			return (
				Object?.values(selectedStaff ?? {})?.length <= 0 &&
				Object?.values(defaultStaff ?? {})?.length <= 0
			);
		} else if (p === PaymentClass.Voucher || p === PaymentClass.Others) {
			return watch('referenceNo') === undefined;
		} else {
			return (
				watch('defaultPaymentID') === undefined ||
				watch('defaultPaymentID') === null
			);
		}
	};

	// useEffect(() => {
	// 	if (selectedOrder?.patron?.patronClass === 'Officer') {
	// 		setDefaultOfficer(
	// 			getOfficer?.filter(x => x?.ID === selectedOrder?.patronAccountID)[0],
	// 		);
	// 	}
	// }, [selectedOrder, getOfficer]);

	// useEffect(() => {
	// 	if (selectedOrder?.patron?.patronClass === 'Staff') {
	// 		setDefaultStaff(
	// 			getStaff?.filter(x => x?.ID === selectedOrder?.patronAccountID)[0],
	// 		);
	// 	}
	// }, [selectedOrder, getStaff]);

	const customSortPayment = [
		'CASH',
		'CREDIT',
		'CHEQUE',
		'BANK_TT',
		'ONLINE',
		'VOUCHER',
		'MEMBER',
		'STAFF',
		'OFFICER',
		'ROOM',
		'DEBTOR',
		'NON_GUEST',
		'SUSPEND_FOLIO',
		'SKIPPER',
		'OTHERS',
	];

	function customSort(a: string, b: string) {
		const indexA = customSortPayment.indexOf(a);
		const indexB = customSortPayment.indexOf(b);

		// Handle items not found in custom order
		if (indexA === -1 && indexB === -1) {
			return a.localeCompare(b); // Use default alphabetical sorting
		} else if (indexA === -1) {
			return 1; // Move items not in custom order to the end
		} else if (indexB === -1) {
			return -1; // Move items not in custom order to the end
		}

		return indexA - indexB;
	}

	// let array = [];

	let [array, setArray] = useState([]);
	let [error, setError] = useState(false);
	let [errorMessage, setErrorMessage] = useState('');
	let [isTouch, setIsTouch] = useState(false);
	let [isCancel, setCancel] = useState(false);

	let totalT = 0,
		balanceT = 0;

	array?.map(x => {
		totalT +=
			x?.paymentClass === PaymentClass.Cash
				? receivedAmount
				: isCancel === false
				? x?.amountPayment
				: 0;

		totalT = Number(totalT.toFixed(2));

		// console.log('totalT', totalT);
	});

	if (array?.length === 0 || array === undefined) {
		totalT = 0;
		balanceT = 0;
		setReceivedAmount(0);
		// totalT = receivedAmount > 0 ? totalT + receivedAmount : totalT;
	}

	// if (isCancel === true) {
	// 	array = [];
	// }

	balanceT = Number((total - totalT).toFixed(2));

	// console.log('array', array);
	// console.log('totalT', totalT);
	// console.log('balanceT', balanceT);

	useEffect(() => {
		if (balanceT < 0 && receivedAmount === 0) {
			setError(true);
			setErrorMessage('Amount exceeded bill amount');
		} else if (balanceT > 0) {
			setError(true);
			setErrorMessage('Insufficient amount');
		} else if (balanceT === 0 || balanceT < 0) {
			setError(false);
		}
	}, [balanceT]);

	// console.log('array', array);
	// console.log('data', total, totalT, receivedAmount, balanceT, isCancel);

	const filterPaymentClass = paymentClass => {
		const array = lastArray?.filter(x => x?.paymentClass === paymentClass);

		return array;
	};

	const isPaymentDisabled = (paymentClass, defaultObj, selectedObj, array) => {
		const paymentArray = array?.filter(x => x?.paymentClass === paymentClass);

		if (paymentArray?.length > 0) {
			const defaultRegistrationID = defaultObj?.RegistrationID;
			const selectedRegistrationID = selectedObj?.RegistrationID;
			const amountPayment = paymentArray[0]?.amountPayment;

			if (defaultRegistrationID === undefined) {
				if (
					selectedRegistrationID === undefined ||
					selectedObj?.CreditBalance < amountPayment
				) {
					return true;
				}
			} else if (defaultObj?.CreditBalance < amountPayment) {
				return true;
			}
		}

		return false;
	};

	const searchFieldDisabled = () => {
		return (
			isPaymentDisabled(PaymentClass.Room, defaultRoom, selectedRoom, array) ||
			isPaymentDisabled(
				PaymentClass.Debtor,
				defaultDebtor,
				selectedDebtor,
				array,
			) ||
			isPaymentDisabled(
				PaymentClass.NonGuest,
				defaultNonGuest,
				selectedNonGuest,
				array,
			) ||
			isPaymentDisabled(
				PaymentClass.SuspendFolio,
				defaultFolio,
				selectedFolio,
				array,
			) ||
			(array?.filter(x => x?.paymentClass === PaymentClass.Staff)?.length > 0 &&
				defaultStaff?.ID === undefined &&
				selectedStaff?.ID === undefined) ||
			(array?.filter(x => x?.paymentClass === PaymentClass.Officer)?.length >
				0 &&
				defaultOfficer?.ID === undefined &&
				selectedOfficer?.ID === undefined)
		);
	};

	const isSignature = () => {
		const isSignature =
			array?.filter(
				x =>
					x?.paymentClass === PaymentClass.Room ||
					x?.paymentClass === PaymentClass.Debtor ||
					x?.paymentClass === PaymentClass.NonGuest ||
					x?.paymentClass === PaymentClass.SuspendFolio ||
					x?.paymentClass === PaymentClass.Member,
			)?.length > 0
				? true
				: false;

		return isSignature;
	};

	const hasSuspendFolioIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.SuspendFolio)?.length >
			0 &&
		Object?.values(selectedFolio ?? {})?.length <= 0 &&
		Object?.values(defaultFolio ?? {})?.length <= 0;
	const hasDebtorIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.Debtor)?.length > 0 &&
		(selectedDebtor?.CreditBalance <= 0 ||
			(Object?.values(selectedDebtor ?? {})?.length <= 0 &&
				Object?.values(defaultDebtor ?? {})?.length <= 0));
	const hasNonGuestIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.NonGuest)?.length > 0 &&
		(selectedNonGuest?.CreditBalance <= 0 ||
			(Object?.values(selectedNonGuest ?? {})?.length <= 0 &&
				Object?.values(defaultNonGuest ?? {})?.length <= 0));
	const hasOfficerIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.Officer)?.length > 0 &&
		Object?.values(selectedOfficer ?? {})?.length <= 0 &&
		Object?.values(defaultOfficer ?? {})?.length <= 0;
	const hasStaffIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.Staff)?.length > 0 &&
		Object?.values(selectedStaff ?? {})?.length <= 0 &&
		Object?.values(defaultStaff ?? {})?.length <= 0;
	const hasRoomIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.Room)?.length > 0 &&
		(selectedRoom?.CreditBalance <= 0 ||
			(Object?.values(selectedRoom ?? {})?.length <= 0 &&
				Object?.values(defaultRoom ?? {})?.length <= 0));
	const hasAnyIssue =
		hasSuspendFolioIssue ||
		hasDebtorIssue ||
		hasNonGuestIssue ||
		hasOfficerIssue ||
		hasStaffIssue ||
		hasRoomIssue;

	return (
		<>
			{(MenuXInhouseListLoading || MenuXDebtorListLoading) && <Loading />}
			{getRoundingPolicyLoading && <Loading />}

			<CommonDialogV2
				open={openDialog}
				hidden={openSignature || openReason}
				fullWidth
				disableEnforceFocus
				onClose={() => {
					setReceivedAmount(0);
					reset();
					setOpenDialog(false);
					setSelectedRoom({});
					setSelectedNonGuest({});
					setSelectedFolio({});
					setSelectedDebtor({});
					setSelectedOfficer({});
					setSelectedStaff({});
					setSelectedCredit({});
					setIsTouch(false);
					dispatch({
						type: 'reset',
						payload: false,
					});
					setRadioError(false);
					setCancel(true);
					handleSubmit(data => {
						const { defaultPayment } = data;
						setArray(defaultPayment?.filter(x => x?.selected === true));
					})();
					setDisablePaymentClass(null);
					setArray(null);
				}}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div
													className="title highlight-text flex-space"
													style={{ color: '#ff9800' }}
												>
													{paymentType === 'voidPayment'
														? 'New Payment'
														: paymentType === 'settlement'
														? 'Bill Settlement'
														: 'Payment'}
												</div>
												<div className="highlight-text title">{`${
													getOutlet[0]?.currencyCode
												}${amtStr(total)}`}</div>
											</div>
										</div>
									</div>
									{paymentType === 'settlement' && (
										<div className="infoline-content ">
											<div className="xsTitle infoline">
												{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
											</div>
										</div>
										// <div className="session">
										// 	<div className="flex session">
										// 		<div
										// 			className="xsTitle flex-space infoline-content"
										// 			style={{ color: 'black' }}
										// 		>
										// 			{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
										// 		</div>
										// 	</div>
										// </div>
									)}
									{paymentType === 'takeAway' && (
										<div className="infoline-content ">
											<div className="xsTitle infoline">
												{`#${selectedOrder?.docNo} | ${selectedOrder?.patronName}`}
											</div>
										</div>
									)}
								</div>
							</>
						),
					},
					// <ListItemText
					// 	primary={
					// 	}
					// />
					body: () => (
						<>
							<span className="mdDesc" style={{ paddingLeft: '15px' }}>
								{'Payment Method'}
							</span>
							<RadioGroup
								aria-label="paymentMethod"
								onChange={e => {
									register(`defaultPaymentID`);
									setValue(`defaultPaymentID`, e.target.value);
								}}
							>
								{getDefaultPayment
									?.filter(s => s?.isActive === true)
									?.filter(s => s?.paymentClass !== PaymentClass.UnsettledBill)
									?.sort((a, b) => customSort(a?.paymentClass, b?.paymentClass))
									?.map((v, i) => {
										const defaultPayment = `defaultPayment[${i}]`;
										const array1 = watch('array', array);

										console.log(
											watch(`${defaultPayment}.length`),

											'disablePaymentClass',
										);

										const isDisabled =
											disablePaymentClass === PaymentClass?.Cash ||
											disablePaymentClass === PaymentClass?.BankTt ||
											disablePaymentClass === PaymentClass?.Credit ||
											disablePaymentClass === PaymentClass?.Cheque ||
											disablePaymentClass === PaymentClass?.Online ||
											disablePaymentClass === PaymentClass?.Voucher ||
											disablePaymentClass === PaymentClass?.Member ||
											disablePaymentClass === PaymentClass?.Staff ||
											disablePaymentClass === PaymentClass?.Officer ||
											disablePaymentClass === PaymentClass?.Skipper ||
											disablePaymentClass === PaymentClass?.Others
												? v?.paymentClass === PaymentClass.NonGuest ||
												  v?.paymentClass === PaymentClass.Room ||
												  v?.paymentClass === PaymentClass.Debtor ||
												  v?.paymentClass === PaymentClass.SuspendFolio
												: disablePaymentClass === PaymentClass?.Room
												? v?.paymentClass !== PaymentClass?.Room
												: disablePaymentClass === PaymentClass?.NonGuest
												? v?.paymentClass !== PaymentClass?.NonGuest
												: disablePaymentClass === PaymentClass?.Debtor
												? v?.paymentClass !== PaymentClass?.Debtor
												: disablePaymentClass === PaymentClass?.SuspendFolio
												? v?.paymentClass !== PaymentClass?.SuspendFolio
												: false;

										return (
											<>
												<Grid container>
													<Grid item xs={1}>
														<FormControl
															component="fieldset"
															style={{ paddingRight: 'revert' }}
														>
															<Checkbox
																onChange={e => {
																	register(
																		`${defaultPayment}.defaultPaymentID`,
																	);
																	setValue(
																		`${defaultPayment}.defaultPaymentID`,
																		v?.ID,
																	);

																	register(`${defaultPayment}.paymentClass`);
																	setValue(
																		`${defaultPayment}.paymentClass`,
																		v?.paymentClass,
																	);

																	register(`${defaultPayment}.amountPayment`);
																	setValue(
																		`${defaultPayment}.amountPayment`,
																		balanceT < 0 ? 0 : balanceT,
																	);

																	register(`${defaultPayment}.registrationID`);
																	setValue(
																		`${defaultPayment}.registrationID`,
																		selectedOrder?.patronAccountID,
																	);
																	register(`${defaultPayment}.length`);
																	setValue(
																		`${defaultPayment}.length`,
																		array?.length ?? 0,
																	);

																	handleSubmit(data => {
																		const { defaultPayment } = data;
																		setArray(
																			defaultPayment?.filter(
																				x => x?.selected === true,
																			),
																		);
																		setCancel(false);
																	})();
																}}
																onClick={e => {
																	if (watch(`defaultPaymentID`) !== v?.ID) {
																		handleCallLazy(v);
																		handleRadio(v);
																		chargesRefetch();
																		setSelectedCredit({});
																		// setSelectedRoom({});
																		// setSelectedNonGuest({});
																		// setSelectedFolio({});
																		// setSelectedDebtor({});
																		// setHotelGuestInfo({});
																		if (
																			watch(`${defaultPayment}.selected`) ===
																			true
																		) {
																			setDisablePaymentClass(v?.paymentClass);
																		} else if (
																			watch(`${defaultPayment}.selected`) ===
																				false &&
																			watch(`${defaultPayment}.length`) === 0
																		) {
																			setDisablePaymentClass(null);
																			setSelectedRoom({});
																			setSelectedNonGuest({});
																			setSelectedFolio({});
																			setSelectedDebtor({});
																			setSelectedOfficer({});
																			setSelectedStaff({});
																			setSelectedCredit({});
																		}
																	}
																}}
																name={`${defaultPayment}.selected`}
																inputRef={register()}
																color="primary"
																disabled={isDisabled}
															/>
														</FormControl>
													</Grid>

													<Grid
														item
														xs={6}
														style={{ paddingTop: '10px', paddingLeft: '14px' }}
													>
														<div
														// className="xxTitle left"
														>
															{v?.paymentTypeInfo?.description}
														</div>
													</Grid>

													{watch(`${defaultPayment}.selected`) &&
														v?.paymentClass !== PaymentClass.Cash && (
															<Grid item xs={5}>
																<div className="desc text-noflow">
																	{'Amount'}
																</div>
																<div
																	className="desc form-visitor-price"
																	style={{
																		color: '#ff9800',
																		fontWeight: 'bold',
																		marginTop: '-9px',
																	}}
																>
																	<Controller
																		render={({ onChange, onBlur, value }) => (
																			<NumberFormat
																				customInput={TextField}
																				allowNegative={false}
																				fullWidth
																				autoComplete="off"
																				isNumericString
																				decimalScale={2}
																				fixedDecimalScale
																				thousandSeparator
																				// label="Amount"
																				// value={getFormattedValue()}
																				defaultValue={
																					balanceT < 0 ? 0 : balanceT
																				}
																				variant="outlined"
																				margin="dense"
																				// ref={register}
																				// disabled={
																				//   !watch(`${patronPrice}.selected`) === false
																				//     ? false
																				//     : true
																				// }
																				max={balanceT}
																				className="custom-add desc"
																				onValueChange={(value: any) => {
																					const { floatValue } = value;
																					register(
																						`${defaultPayment}.amountPayment`,
																					);
																					setValue(
																						`${defaultPayment}.amountPayment`,
																						floatValue,
																					);
																					handleSubmit(data => {
																						const { defaultPayment } = data;
																						setArray(
																							defaultPayment?.filter(
																								x => x?.selected === true,
																							),
																						);
																					})();

																					// if (balanceT !== 0) {
																					// 	console.log('1', balanceT);
																					// 	setError(true);
																					// } else if (balanceT === 0) {
																					// 	console.log('2', balanceT);
																					// 	setError(false);
																					// }
																				}}
																			/>
																		)}
																		name={`patron`}
																		control={control}
																		ref={register}
																		className="desc custom-add"
																		// helperText={
																		//   errors?.patronPrice?.[i]?.patronInfo?.[0]?.[
																		//     visitorID
																		//   ]?.message
																		// }
																		// error={
																		//   errors?.patronPrice?.[i]?.patronInfo?.[0]?.[
																		//     visitorID
																		//   ]
																		// }
																	/>
																</div>
															</Grid>
														)}
												</Grid>

												{watch(`${defaultPayment}.selected`) && error === true && (
													<span
														className="desc"
														style={{ color: 'red', textAlign: 'right' }}
													>
														{errorMessage}
													</span>
												)}

												{//v?.paymentClass === PaymentClass.Voucher &&
												//state.voucher &&
												watch(`${defaultPayment}.selected`) &&
												watch(`${defaultPayment}.paymentClass`) ===
													PaymentClass.Voucher ? (
													<Grid container>
														<Grid
															item
															xs={1}
															style={{
																maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
															}}
														></Grid>
														<Grid item xs={10} style={{ marginLeft: '4px' }}>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<TextField
																		// format="#### #### #### ####"
																		// mask="_"
																		fullWidth
																		autoComplete="off"
																		// isNumericString
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		margin="dense"
																		label="Voucher Code"
																		onChange={e => {
																			register(`${defaultPayment}.referenceNo`);
																			setValue(
																				`${defaultPayment}.referenceNo`,
																				e.target.value,
																			);
																		}}
																	/>
																)}
																name="referenceNo"
																label={'Voucher Code'}
																fullWidth
																autoComplete="off"
																multiline={false}
																margin="dense"
																// style={{
																// 	marginTop: '3px',
																// 	marginBottom: '3px',
																// }}
																// onChange={e => {
																// 	console.log('e', e.target.value);
																// 	register(`${defaultPayment}.vc`);
																// 	setValue(`${defaultPayment}.vc`, e);
																// }}
																ref={register}
																control={control}
																className="referenceNo"
																helperText={
																	radioError &&
																	watch('referenceNo') === undefined
																		? 'Voucher Code must not be empty'
																		: null
																}
																error={
																	radioError &&
																	watch('referenceNo') === undefined
																}
															/>
														</Grid>
													</Grid>
												) : null}

												{//v?.paymentClass === PaymentClass.BankTt &&
												// state.bankTT &&
												watch(`${defaultPayment}.selected`) &&
												watch(`${defaultPayment}.paymentClass`) ===
													PaymentClass.BankTt ? (
													<Grid container>
														<Grid
															item
															xs={1}
															style={{
																maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
															}}
														></Grid>
														<Grid item xs={10} style={{ marginLeft: '4px' }}>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<TextField
																		// format="#### #### #### ####"
																		// mask="_"
																		fullWidth
																		autoComplete="off"
																		// isNumericString
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		margin="dense"
																		label="Remarks"
																		onChange={e => {
																			register(`${defaultPayment}.remark`);
																			setValue(
																				`${defaultPayment}.remark`,
																				e.target.value,
																			);
																		}}
																	/>
																)}
																name="remark"
																label={'Remarks'}
																fullWidth
																autoComplete="off"
																multiline={false}
																margin="dense"
																// style={{
																// 	marginTop: '3px',
																// 	marginBottom: '3px',
																// }}
																ref={register}
																control={control}
																className="remark"
																helperText={
																	radioError && watch('remark') === undefined
																		? 'Input must not be empty'
																		: null
																}
																error={
																	radioError && watch('remark') === undefined
																}
															/>
														</Grid>
													</Grid>
												) : null}

												{//v?.paymentClass === PaymentClass.Others &&
												//state.others &&
												watch(`${defaultPayment}.selected`) &&
												watch(`${defaultPayment}.paymentClass`) ===
													PaymentClass.Others ? (
													<Grid container>
														<Grid
															item
															xs={1}
															style={{
																maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
															}}
														></Grid>
														<Grid item xs={10} style={{ marginLeft: '4px' }}>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<TextField
																		// format="#### #### #### ####"
																		// mask="_"
																		fullWidth
																		autoComplete="off"
																		// isNumericString
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		margin="dense"
																		label="Remarks"
																		onChange={e => {
																			register(`${defaultPayment}.referenceNo`);
																			setValue(
																				`${defaultPayment}.referenceNo`,
																				e.target.value,
																			);
																		}}
																	/>
																)}
																name="referenceNo"
																label={'Remarks'}
																fullWidth
																autoComplete="off"
																multiline={false}
																margin="dense"
																// style={{
																// 	marginTop: '3px',
																// 	marginBottom: '3px',
																// }}
																ref={register}
																control={control}
																className="referenceNo"
																helperText={
																	radioError &&
																	watch('referenceNo') === undefined
																		? 'Input must not be empty'
																		: null
																}
																error={
																	radioError &&
																	watch('referenceNo') === undefined
																}
															/>
														</Grid>
													</Grid>
												) : null}

												{//v?.paymentClass === PaymentClass.Member &&
												//state.member &&
												watch(`${defaultPayment}.selected`) &&
												watch(`${defaultPayment}.paymentClass`) ===
													PaymentClass.Member ? (
													<Grid container>
														<Grid
															item
															xs={1}
															style={{
																maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
															}}
														></Grid>
														<Grid item xs={10} style={{ marginLeft: '4px' }}>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<TextField
																		// format="#### #### #### ####"
																		// mask="_"
																		fullWidth
																		autoComplete="off"
																		// isNumericString
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		margin="dense"
																		label="Remarks"
																		onChange={e => {
																			register(`${defaultPayment}.remark`);
																			setValue(
																				`${defaultPayment}.remark`,
																				e.target.value,
																			);
																		}}
																	/>
																)}
																name="remark"
																label={'Remarks'}
																fullWidth
																autoComplete="off"
																multiline={false}
																margin="dense"
																// style={{
																// 	marginTop: '3px',
																// 	marginBottom: '3px',
																// }}
																ref={register}
																control={control}
																className="remark"
																helperText={
																	radioError && watch('remark') === undefined
																		? 'Input must not be empty'
																		: null
																}
																error={
																	radioError && watch('remark') === undefined
																}
															/>
														</Grid>
													</Grid>
												) : null}

												{//v?.paymentClass === PaymentClass.Online &&
												//state.onlinePayment &&
												watch(`${defaultPayment}.selected`) &&
												watch(`${defaultPayment}.paymentClass`) ===
													PaymentClass.Online ? (
													<Grid container>
														<Grid
															item
															xs={1}
															style={{
																maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
															}}
														></Grid>
														<Grid item xs={10} style={{ marginLeft: '4px' }}>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<TextField
																		// format="#### #### #### ####"
																		// mask="_"
																		fullWidth
																		autoComplete="off"
																		// isNumericString
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		margin="dense"
																		label="Remarks"
																		onChange={e => {
																			register(`${defaultPayment}.remark`);
																			setValue(
																				`${defaultPayment}.remark`,
																				e.target.value,
																			);
																		}}
																	/>
																)}
																name="remark"
																label={'Remarks'}
																fullWidth
																autoComplete="off"
																multiline={false}
																margin="dense"
																// style={{
																// 	marginTop: '3px',
																// 	marginBottom: '3px',
																// }}
																ref={register}
																control={control}
																className="remark"
																helperText={
																	radioError && watch('remark') === undefined
																		? 'Input must not be empty'
																		: null
																}
																error={
																	radioError && watch('remark') === undefined
																}
															/>
														</Grid>
													</Grid>
												) : null}

												{//v?.paymentClass === PaymentClass.Skipper &&
												//state.skipperAccount &&
												watch(`${defaultPayment}.selected`) &&
												watch(`${defaultPayment}.paymentClass`) ===
													PaymentClass.Skipper ? (
													<Grid container>
														<Grid
															item
															xs={1}
															style={{
																maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
															}}
														></Grid>
														<Grid item xs={10} style={{ marginLeft: '4px' }}>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<TextField
																		// format="#### #### #### ####"
																		// mask="_"
																		fullWidth
																		autoComplete="off"
																		// isNumericString
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		margin="dense"
																		label="Remarks"
																		onChange={e => {
																			register(`${defaultPayment}.remark`);
																			setValue(
																				`${defaultPayment}.remark`,
																				e.target.value,
																			);
																		}}
																	/>
																)}
																name="remark"
																label={'Remarks'}
																fullWidth
																autoComplete="off"
																multiline={false}
																margin="dense"
																// style={{
																// 	marginTop: '3px',
																// 	marginBottom: '3px',
																// }}
																ref={register}
																control={control}
																className="remark"
																helperText={
																	radioError && watch('remark') === undefined
																		? 'Input must not be empty'
																		: null
																}
																error={
																	radioError && watch('remark') === undefined
																}
															/>
														</Grid>
													</Grid>
												) : null}

												{//v?.paymentClass === PaymentClass.Cheque &&
												//state.cheque &&
												watch(`${defaultPayment}.selected`) &&
												watch(`${defaultPayment}.paymentClass`) ===
													PaymentClass.Cheque ? (
													<Grid container>
														<Grid
															item
															xs={1}
															style={{
																maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
															}}
														></Grid>
														<Grid item xs={10} style={{ marginLeft: '4px' }}>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<TextField
																		// format="#### #### #### ####"
																		// mask="_"
																		fullWidth
																		autoComplete="off"
																		// isNumericString
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		margin="dense"
																		label="Remarks"
																		onChange={e => {
																			register(`${defaultPayment}.remark`);
																			setValue(
																				`${defaultPayment}.remark`,
																				e.target.value,
																			);
																		}}
																	/>
																)}
																name="remark"
																label={'Remarks'}
																fullWidth
																autoComplete="off"
																multiline={false}
																margin="dense"
																// style={{
																// 	marginTop: '3px',
																// 	marginBottom: '3px',
																// }}
																ref={register}
																control={control}
																className="remark"
																helperText={
																	`${defaultPayment}.selected` === undefined
																		? 'Input must not be empty'
																		: null
																}
																error={`${defaultPayment}.remark` === undefined}
															/>
														</Grid>
													</Grid>
												) : null}

												{// v?.paymentClass === PaymentClass.Cash &&
												// state?.cashAmount &&
												// !getRoundingPolicyLoading &&
												watch(`${defaultPayment}.selected`) === true &&
												watch(`${defaultPayment}.paymentClass`) ===
													PaymentClass.Cash ? (
													<Grid container>
														<Grid
															item
															xs={1}
															style={{
																maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
															}}
														></Grid>

														<Grid
															item
															xs={5}
															style={{
																marginRight: '4px',
															}}
														>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<NumberFormat
																		customInput={TextField}
																		fullWidth
																		autoComplete="off"
																		isNumericString
																		thousandSeparator
																		decimalScale={2}
																		fixedDecimalScale
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		label="Received Amt"
																		margin="dense"
																		value={receivedAmount}
																		defaultValue={minVal}
																		onValueChange={e => {
																			setReceivedAmount(
																				getRoundingPolicy[0]?.inUse === true
																					? getRoundedAmt(
																							e?.floatValue ?? 0,
																							getRoundingPolicy[0]
																								?.smallestDenomination,
																					  )
																					: receivedAmount,
																			);
																			setValue(
																				'receivedAmount',
																				getRoundingPolicy[0]?.inUse === true
																					? getRoundedAmt(
																							e?.floatValue,
																							getRoundingPolicy[0]
																								?.smallestDenomination,
																					  )
																					: receivedAmount,
																			);
																		}}
																		helperText={
																			!!!watch('receivedAmount')
																				? 'Amount is required'
																				: null
																		}
																		error={
																			!!!watch('receivedAmount') ? true : false
																		}
																	/>
																)}
																style={{
																	paddingBottom: '0px',
																	float: 'right',
																}}
																name="receivedAmount"
																label="Received Amt"
																control={control}
																ref={register}
															/>
														</Grid>
														<Grid item xs={5} style={{ marginLeft: '4px' }}>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<NumberFormat
																		customInput={TextField}
																		fullWidth
																		autoComplete="off"
																		isNumericString
																		thousandSeparator
																		decimalScale={2}
																		fixedDecimalScale
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		label="Change Amt"
																		disabled
																		margin="dense"
																		value={totalT - total}
																		defaultValue={0}
																		onValueChange={e => {
																			setChangeAmount(
																				getRoundedAmt(
																					e?.floatValue ?? 0,
																					getRoundingPolicy[0]
																						?.smallestDenomination,
																				),
																			);
																		}}
																	/>
																)}
																style={{
																	paddingBottom: '0px',
																	float: 'right',
																}}
																name="changeAmount"
																label="Change Amt"
																control={control}
																ref={register}
															/>
														</Grid>
													</Grid>
												) : null}

												{// state?.cardDropDown &&
												// v?.paymentClass === PaymentClass.Credit &&
												watch(`${defaultPayment}.selected`) &&
												watch(`${defaultPayment}.paymentClass`) ===
													PaymentClass.Credit ? (
													<Grid container>
														<Grid
															item
															xs={1}
															style={{
																maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
															}}
														></Grid>

														<Grid
															item
															xs={5}
															style={{
																marginRight: '4px',
															}}
														>
															<Controller
																as={CardDropDownDownField()}
																label={'Card Type'}
																name={'cardType'}
																style={{
																	marginTop: '0px',
																	marginBottom: '0px',
																}}
																autoComplete="off"
																control={control}
																multiline
																fullWidth
																margin="dense"
																ref={register}
																helperText={
																	filterPaymentClass(PaymentClass.Credit)
																		?.length > 0 &&
																	filterPaymentClass(PaymentClass.Credit)[0]
																		?.creditCardType === undefined &&
																	watch('cardType') === undefined
																		? 'Must select at least one option'
																		: filterPaymentClass(PaymentClass.Credit)
																				?.length > 0 &&
																		  filterPaymentClass(PaymentClass.Credit)[0]
																				?.creditCardType === undefined &&
																		  Number(watch('cardType')?.CreditBalance) <
																				balanceT
																		? 'Not enough Credit Balance!'
																		: null
																}
																error={
																	(filterPaymentClass(PaymentClass.Credit)
																		?.length > 0 &&
																		filterPaymentClass(PaymentClass.Credit)[0]
																			?.creditCardType === undefined &&
																		watch('cardType') === undefined) ||
																	(filterPaymentClass(PaymentClass.Credit)
																		?.length > 0 &&
																		filterPaymentClass(PaymentClass.Credit)[0]
																			?.creditCardType === undefined &&
																		Number(watch('cardType')?.CreditBalance) <
																			balanceT)
																}
															/>
														</Grid>
														<Grid item xs={5} style={{ marginLeft: '4px' }}>
															<Controller
																as={
																	<NumberFormat
																		// format="#### #### #### ####"
																		// mask="_"
																		allowEmptyFormatting
																		customInput={TextField}
																		fullWidth
																		autoComplete="off"
																		// isNumericString
																		style={{
																			marginBottom: '0px',
																			marginTop: '0px',
																		}}
																		margin="dense"
																		onValueChange={(value: any) => {
																			const { formattedValue } = value;
																			register(`${defaultPayment}.cardRef`);
																			setValue(
																				`${defaultPayment}.cardRef`,
																				formattedValue,
																			);
																		}}
																	/>
																}
																name="cardReference"
																label={'Card Ref.'}
																fullWidth
																autoComplete="off"
																multiline={false}
																margin="dense"
																// style={{
																// 	marginTop: '3px',
																// 	marginBottom: '3px',
																// }}
																ref={register}
																control={control}
																className="cardReference"
																helperText={
																	filterPaymentClass(PaymentClass.Credit)
																		?.length > 0 &&
																	filterPaymentClass(PaymentClass.Credit)[0]
																		?.cardNo === undefined &&
																	watch('cardReference') === undefined
																		? 'Card Ref. must not be empty'
																		: null
																}
																error={
																	filterPaymentClass(PaymentClass.Credit)
																		?.length > 0 &&
																	filterPaymentClass(PaymentClass.Credit)[0]
																		?.cardNo === undefined &&
																	watch('cardReference') === undefined
																}
															/>
														</Grid>
													</Grid>
												) : null}

												{!MenuXInhouseListLoading &&
													filterCond?.room &&
													v?.paymentClass == PaymentClass.Room &&
													watch(`${defaultPayment}.selected`) && (
														<SearchField
															data={v?.paymentClass}
															paymentEnum={PaymentClass.Room}
															tempState={tempState}
															reducerState={state?.hotelSearchField}
															label={'Search Hotel Guest'}
															defaultListField={{
																defaultField: defaultRoom,
																paymentEnum: PaymentClass.Room,
																data: 'GuestName',
																type: 'RoomNo',
																balance: 'CreditBalance',
															}}
															listField={{
																filterCondition: 'GuestType',
																fieldKey: ['RoomGuest'],
																arg: 'RegistrationID',
																filteredList: filteredList,
																setSelectedListItem: setSelectedRoom,
																selectedListItem: selectedRoom,
																data: 'GuestName',
																type: 'RoomNo',
																balance: 'CreditBalance',
																paymentEnum: PaymentClass.Room,
																register: register,
																setValue: setValue,
																defaultPayment: defaultPayment,
															}}
															selectedField={selectedRoom}
															defaultField={defaultRoom}
															selectedOrder={selectedOrder}
															setSelectedField={setSelectedRoom}
															total={watch(`${defaultPayment}.amountPayment`)}
															onChange={e => {
																handleSearch(`"${e?.target?.value}"`, [
																	`GuestName`,
																	`RoomNo`,
																]);
																if (filteredList?.length === 1) {
																	setSelectedRoom(filteredList[0]);
																}
																setTempState(e?.target?.value);
															}}
															defaultValue={
																selectedOrder?.patronAccountID
																	? getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
																			x =>
																				x?.RegistrationID ===
																					selectedOrder?.patronAccountID &&
																				x?.GuestType === 'RoomGuest',
																	  )[0]?.GuestName
																	: null
															}
														/>
													)}

												{!MenuXInhouseListLoading &&
													filterCond?.nonGuest &&
													v?.paymentClass == PaymentClass.NonGuest &&
													watch(`${defaultPayment}.selected`) && (
														<SearchField
															data={v?.paymentClass}
															paymentEnum={PaymentClass.NonGuest}
															tempState={tempState}
															reducerState={state?.nonGuestSearchField}
															label={'Search Guest'}
															defaultListField={{
																defaultField: defaultNonGuest,
																paymentEnum: PaymentClass.NonGuest,
																data: 'GuestName',
																type: 'RoomNo',
																balance: 'CreditBalance',
															}}
															listField={{
																filterCondition: 'GuestType',
																fieldKey: ['NonGuest'],
																arg: 'RegistrationID',
																filteredList: filteredList,
																setSelectedListItem: setSelectedNonGuest,
																selectedListItem: selectedNonGuest,
																data: 'GuestName',
																type: 'RoomNo',
																balance: 'CreditBalance',
																paymentEnum: PaymentClass.NonGuest,
																register: register,
																setValue: setValue,
																defaultPayment: defaultPayment,
															}}
															selectedField={selectedNonGuest}
															defaultField={defaultNonGuest}
															selectedOrder={selectedOrder}
															setSelectedField={setSelectedNonGuest}
															total={watch(`${defaultPayment}.amountPayment`)}
															onChange={e => {
																handleSearch(`"${e?.target?.value}"`, [
																	`GuestName`,
																	`RoomNo`,
																]);
																if (filteredList?.length === 1) {
																	setSelectedNonGuest(filteredList[0]);
																}
																setTempState(e?.target?.value);
															}}
															defaultValue={
																selectedOrder?.patronAccountID
																	? getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
																			x =>
																				x?.RegistrationID ===
																					selectedOrder?.patronAccountID &&
																				x?.GuestType === 'NonGuest',
																	  )[0]?.GuestName
																	: null
															}
														/>
													)}

												{!MenuXInhouseListLoading &&
													filterCond?.folio &&
													v?.paymentClass == PaymentClass.SuspendFolio &&
													watch(`${defaultPayment}.selected`) && (
														<SearchField
															data={v?.paymentClass}
															paymentEnum={PaymentClass.SuspendFolio}
															tempState={tempState}
															reducerState={state.folioSearchField}
															label={'Search Suspend Folio'}
															defaultListField={{
																defaultField: defaultFolio,
																paymentEnum: PaymentClass.SuspendFolio,
																data: 'GuestName',
																type: 'RoomNo',
																balance: 'CreditBalance',
															}}
															listField={{
																filterCondition: 'GuestType',
																fieldKey: ['SuspendFolio'],
																arg: 'RegistrationID',
																filteredList: filteredList,
																setSelectedListItem: setSelectedFolio,
																selectedListItem: selectedFolio,
																data: 'GuestName',
																type: 'RoomNo',
																balance: 'CreditBalance',
																paymentEnum: PaymentClass.SuspendFolio,
																register: register,
																setValue: setValue,
																defaultPayment: defaultPayment,
															}}
															selectedField={selectedFolio}
															defaultField={defaultFolio}
															selectedOrder={selectedOrder}
															setSelectedField={setSelectedFolio}
															total={watch(`${defaultPayment}.amountPayment`)}
															onChange={e => {
																handleSearch(`"${e?.target?.value}"`, [
																	`GuestName`,
																	`RoomNo`,
																]);
																if (filteredList?.length === 1) {
																	setSelectedFolio(filteredList[0]);
																}
																setTempState(e?.target?.value);
															}}
															defaultValue={
																selectedOrder?.patronAccountID
																	? getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
																			x =>
																				x?.RegistrationID ===
																					selectedOrder?.patronAccountID &&
																				x?.GuestType === 'SuspendFolio',
																	  )[0]?.GuestName
																	: null
															}
														/>
													)}

												{!MenuXDebtorListLoading &&
													filterCond?.debtor &&
													v?.paymentClass == PaymentClass.Debtor &&
													watch(`${defaultPayment}.selected`) && (
														<SearchField
															data={v?.paymentClass}
															paymentEnum={PaymentClass.Debtor}
															tempState={tempState}
															reducerState={state?.debtorSearchField}
															label={'Search Hotel Debtor'}
															defaultListField={{
																defaultField: defaultDebtor,
																paymentEnum: PaymentClass.Debtor,
																balance: 'CreditBalance',
																data: 'GuestName',
																type: 'GuestType',
															}}
															listField={{
																filterCondition: 'GuestStatus',
																fieldKey: ['DEBTOR'],
																arg: 'RegistrationID',
																filteredList: filteredList,
																setSelectedListItem: setSelectedDebtor,
																selectedListItem: selectedDebtor,
																balance: 'CreditBalance',
																data: 'GuestName',
																type: 'GuestType',
																paymentEnum: PaymentClass.Debtor,
																register: register,
																setValue: setValue,
																defaultPayment: defaultPayment,
															}}
															selectedField={selectedDebtor}
															defaultField={defaultDebtor}
															selectedOrder={selectedOrder}
															setSelectedField={setSelectedDebtor}
															total={watch(`${defaultPayment}.amountPayment`)}
															onChange={e => {
																handleSearch(`'"${e?.target?.value}"`, [
																	`GuestType`,
																	`GuestName`,
																]);
																if (filteredList?.length === 1) {
																	setSelectedDebtor(filteredList[0]);
																}
																setTempState(e?.target?.value);
															}}
															defaultValue={
																selectedOrder?.registrationID
																	? getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
																			x =>
																				x?.RegistrationID ===
																				selectedOrder?.registrationID,
																	  )[0]?.GuestName
																	: null
															}
														/>
													)}

												{!loadingOfficer &&
													filterCond?.officer &&
													v?.paymentClass == PaymentClass.Officer &&
													watch(`${defaultPayment}.selected`) && (
														<SearchField
															data={v?.paymentClass}
															paymentEnum={PaymentClass.Officer}
															tempState={tempState}
															reducerState={state?.officerSearchField}
															label={'Search Officer List'}
															defaultListField={{
																defaultField: defaultOfficer,
																paymentEnum: PaymentClass.Officer,
																data: 'name',
																type: 'designation',
																balance: 'mobileNo',
															}}
															listField={{
																fieldKey: ['OFFICER'],
																arg: 'ID',
																filteredList: filteredList,
																setSelectedListItem: setSelectedOfficer,
																selectedListItem: selectedOfficer,
																balance: 'mobileNo',
																data: 'name',
																type: 'designation',
																paymentEnum: PaymentClass.Officer,
																register: register,
																setValue: setValue,
																defaultPayment: defaultPayment,
															}}
															selectedField={selectedOfficer}
															defaultField={defaultOfficer}
															selectedOrder={selectedOrder}
															setSelectedField={setSelectedOfficer}
															total={total}
															onChange={e => {
																handleSearch(`'"${e?.target?.value}"`, [
																	'name',
																	'designation',
																]);
																if (filteredList?.length === 1) {
																	setSelectedOfficer(filteredList[0]);
																}
																setTempState(e?.target?.value);
															}}
															defaultValue={
																selectedOrder?.patronAccountID
																	? getOfficer?.filter(
																			x =>
																				x?.ID ===
																				selectedOrder?.patronAccountID,
																	  )[0]?.name
																	: null
															}
														/>
													)}

												{!loadingStaff &&
													filterCond?.staff &&
													v?.paymentClass == PaymentClass.Staff &&
													watch(`${defaultPayment}.selected`) && (
														<SearchField
															data={v?.paymentClass}
															paymentEnum={PaymentClass.Staff}
															tempState={tempState}
															reducerState={state?.staffSearchField}
															label={'Search Staff List'}
															defaultListField={{
																defaultField: defaultStaff,
																paymentEnum: PaymentClass.Staff,
																data: 'name',
																type: 'designation',
																balance: 'mobileNo',
															}}
															listField={{
																fieldKey: ['STAFF'],
																arg: 'ID',
																filteredList: filteredList,
																setSelectedListItem: setSelectedStaff,
																selectedListItem: selectedStaff,
																balance: 'mobileNo',
																data: 'name',
																type: 'designation',
																paymentEnum: PaymentClass.Staff,
																register: register,
																setValue: setValue,
																defaultPayment: defaultPayment,
															}}
															selectedField={selectedStaff}
															defaultField={defaultStaff}
															selectedOrder={selectedOrder}
															setSelectedField={setSelectedStaff}
															total={total}
															onChange={e => {
																handleSearch(`'"${e?.target?.value}"`, [
																	'designation',
																	'name',
																]);
																if (filteredList?.length === 1) {
																	setSelectedStaff(filteredList[0]);
																}
																setTempState(e?.target?.value);
															}}
															defaultValue={
																selectedOrder?.patronAccountID
																	? getStaff?.filter(
																			x =>
																				x?.ID ===
																				selectedOrder?.patronAccountID,
																	  )[0]?.name
																	: null
															}
														/>
													)}
											</>
										);
									})}
							</RadioGroup>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										reset();
										setReceivedAmount(0);
										setSelectedRoom({});
										setSelectedNonGuest({});
										setSelectedFolio({});
										setSelectedDebtor({});
										setSelectedOfficer({});
										setSelectedCredit({});
										setSelectedStaff({});
										setOpenDialog(false);
										setIsTouch(false);
										dispatch({
											type: 'reset',
											payload: false,
										});
										setRadioError(false);
										setCancel(true);
										handleSubmit(data => {
											const { defaultPayment } = data;
											setArray(
												defaultPayment?.filter(x => x?.selected === true),
											);
										})();
										setDisablePaymentClass(null);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: paymentType === 'settlement' ? 'Confirm' : 'Next',
								props: {
									onClick: () => {
										if (
											paymentType == 'voidPayment' ||
											paymentType == 'voidBill'
										) {
											setOpenReason(true);
										} else {
											if (isSignature() === true) {
												setOpenSignature(true);
											} else if (isSignature() === false) {
												handleSubmit(data => onSubmit(data))();
											}
										}
									},
									variant: 'contained',
									color:
										(receivedAmount === 0 && balanceT !== 0) ||
										balanceT > 0 ||
										(array?.filter(x => x?.paymentClass === PaymentClass.Credit)
											?.length > 0 &&
											watch('cardReference') === undefined) ||
										(array?.filter(x => x?.paymentClass === PaymentClass.Credit)
											?.length > 0 &&
											watch('cardType') === undefined) ||
										hasAnyIssue ||
										// searchFieldDisabled() ||
										loading1 ||
										loading2 ||
										loading3
											? 'inherit'
											: 'primary',
									disabled:
										(receivedAmount === 0 && balanceT !== 0) ||
										balanceT > 0 ||
										(array?.filter(x => x?.paymentClass === PaymentClass.Credit)
											?.length > 0 &&
											watch('cardReference') === undefined) ||
										(array?.filter(x => x?.paymentClass === PaymentClass.Credit)
											?.length > 0 &&
											watch('cardType') === undefined) ||
										hasAnyIssue ||
										// searchFieldDisabled() ||
										loading1 ||
										loading2 ||
										loading3,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const SignatureDialog = (props: SignatureProps) => {
	const {
		isTakeaway,
		handleSubmit,
		onSubmit,
		openSignature,
		selectedOrder,
		setOpenDialog,
		setOpenSignature,
		total,
		loading1,
		loading2,
		loading3,
	} = props;

	const {
		files,
		handleUploadChange,
		previewFiles,
		remove: removeFile,
		handleEditUpload,
		setFiles,
	} = useUploadAttachment();

	const [signatureImage, setSignatureImage] = useState(null);

	let sigCanvas = {};

	const clear = () => {
		const signature: any = sigCanvas;
		signature.clear();
	};

	const saveSigCanvas = () => {
		const signature: any = sigCanvas;
		signature.getTrimmedCanvas().toBlob(blob => {
			setSignatureImage(blob);
		});
	};

	useEffect(() => {
		if (signatureImage) {
			signatureImage['name'] = 'sigName';
			const newFiles = [signatureImage];
			setFiles([...newFiles]);
			handleSubmit(data => onSubmit(data, newFiles))();
		}
	}, [signatureImage]);

	return (
		<>
			<CommonDialogV2
				fullWidth
				open={openSignature}
				onClose={() => setOpenSignature(false)}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div
													className="title highlight-text flex-space"
													style={{ color: '#ff9800' }}
												>
													Signature
												</div>
												<div className="highlight-text title">{`$${amtStr(
													total,
												)}`}</div>
											</div>
										</div>
									</div>
									<div className="infoline-content ">
										<div className="xsTitle infoline">
											{isTakeaway
												? `${selectedOrder?.docNo}`
												: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
											{/* {selectedOrder
												? `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`
												: null} */}
										</div>
									</div>
								</div>
							</>
						),
						// title: 'Signature',
						// subtitle: selectedOrder
						// 	? `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`
						// 	: null,
						// totalAmount: selectedOrder ? `$${amtStr(total)}` : null,
					},
					body: () => (
						<>
							<Grid container style={{ paddingTop: '10px' }}>
								<Grid item xs={8}>
									<span className="sigTitleText"> Sign Here </span>
								</Grid>
								<Grid item xs={4} style={{ textAlign: 'right' }}>
									<span onClick={clear} className="highlight-text">
										{' '}
										Reset
									</span>
								</Grid>
							</Grid>

							<SignatureCanvas
								ref={ref => {
									sigCanvas = ref;
								}}
								penColor="black"
								canvasProps={{
									className: 'sigCanvas',
								}}
							/>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenSignature(false);
									},

									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: () => {
										saveSigCanvas();
										// handleSubmit(onSubmit)();
										setOpenSignature(false);
										setOpenDialog(false);
									},
									variant: 'contained',
									color:
										loading1 || loading2 || loading3 ? 'inherit' : 'primary',
									//disabled: isExceed,
									disabled: loading1 || loading2 || loading3 ? true : false,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const ReasonDialog = (props: ReasonProps) => {
	const {
		openReason,
		setOpenReason,
		isBill,
		setIsBill,
		getReason,
		control,
		register,
		watch,
		paymentID,
		handleSubmit,
		onSubmit,
		setSignatureDialog,
		setOpenDialog,
		loading1,
		loading2,
		loading3,
		isCreditNote,
		setIsCreditNote,
	} = props;

	const color = (fields, loading) => {
		let color: any = 'primary';

		if (fields === true && loading === false) {
			color = 'inherit';
		} else if (fields === false && loading === false) {
			color = 'primary';
		} else if (fields === false && loading === true) {
			color = 'inherit';
		}

		return color;
	};

	const disable = (fields, loading) => {
		let disable: any = false;

		if (fields === true && loading === false) {
			disable = true;
		} else if (fields === false && loading === false) {
			disable = false;
		} else if (fields === false && loading === true) {
			disable = true;
		}

		return disable;
	};

	return (
		<>
			<CommonDialogV2
				fullWidth={true}
				open={openReason}
				onClose={() => {
					setOpenReason(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{isBill
												? isCreditNote
													? 'Credit Note'
													: 'Void Bill'
												: 'Void Payment'}
										</div>
									</div>
								</div>
							</div>
						),
					},

					body: () => (
						<>
							<Controller
								as={
									<TextField select size="small" label="Reason Code">
										{getReason?.map((x, index) => (
											<MenuItem key={index} value={x?.name}>
												{x?.name}
											</MenuItem>
										))}
									</TextField>
								}
								name="reasonCode"
								autoComplete="off"
								control={control}
								multiline
								fullWidth
								margin="normal"
							></Controller>
							<Controller
								as={<TextField />}
								name="remark"
								label="Remark"
								autoComplete="off"
								control={control}
								multiline
								ref={register}
								fullWidth
								margin="normal"
							></Controller>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										if (isBill) {
											setOpenReason(false);
											setIsBill(false);
										} else {
											setOpenReason(false);
										}
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: paymentID(watch('defaultPaymentID'))
									? 'Next'
									: 'Confirm',
								props: {
									onClick: () => {
										if (openReason) {
											if (paymentID(watch('defaultPaymentID'))) {
												// handleSubmit(() => {
												setSignatureDialog(true);
												setOpenReason(false);
												setOpenDialog(false);
												// })();
											} else {
												handleSubmit(data => onSubmit(data))();
											}
										} else {
											setSignatureDialog(true);
											setOpenReason(false);
											setOpenDialog(false);
										}
									},
									variant: 'contained',
									color: color(
										(watch('reasonCode') === undefined &&
											watch('remark') === undefined) ||
											watch('reasonCode') === undefined ||
											watch('remark') === undefined,
										loading1 || loading2 || loading3,
									),
									disabled: disable(
										(watch('reasonCode') === undefined &&
											watch('remark') === undefined) ||
											watch('reasonCode') === undefined ||
											watch('remark') === undefined,
										loading1 || loading2 || loading3,
									),
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
