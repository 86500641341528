import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
} from '@material-ui/core';
import { Check, KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useGetAccountJournalSummaryLazyQuery,
	useGetOutletQuery,
	useGetAssignedOutletByUserQuery,
	useGetAccountJournalSummaryQuery,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auditor, OutletIcon } from '../../../assets';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { formatDate } from 'helpers/hooks/formatDate';

interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

// const details = (description: string, highlighted: any) => {
// 	return (
// 		<span className="desc">
// 			{' '}
// 			{description} <span className="highlight-text">{highlighted}</span>
// 		</span>
// 	);
// };

export const AccountJournalListing = () => {
	useEffect(() => {
		console.log('AccountJournalListing');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const { globalState, dispatch } = useContext(AppContext as any);

	const { handlePermHidden } = usePermissionChecker();
	const outletID = localStorage.getItem('latestOutlet');

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	const [openSwitch, setOpenSwitch] = useState(false);

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			const filteredOutlets: any = getOutlet[0];

			localStorage.setItem('Outlet', JSON.stringify(filteredOutlets));

			// loadAllMealPeriod();
		},
	});

	const [
		loadAccountJournalSummary,
		{
			called: accountJournalSummaryCalled,
			loading: accountJournalSummaryLoading,
			data: accountJournalSummaryData,
		},
	] = useGetAccountJournalSummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
	});

	const {
		loading: accountMappingLoading,
		data: accountMappingData,
	} = useGetAccountJournalSummaryQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!accountJournalSummaryCalled) {
			loadAccountJournalSummary();
		}
	}, [accountJournalSummaryCalled]);

	const menuList = [
		{
			name: 'Accounts Mapping',
			// icon: AppointedOfficerIcon,
			description: 'Last updated on ',
			data1: formatDate(accountMappingData?.bankAccountDate),
			path: `/menu/outlet-app/account-journals/account-mapping`,
			permission: PosPermission.AccountJournalsAccountsMappingShow,
		},
		{
			name: 'GL Journal',
			description: 'Last updated on ',
			data1: formatDate(accountMappingData?.glJournalDate),
			path: `/menu/outlet-app/account-journals/gl-journal`,
			permission: PosPermission.AccountJournalsGlJournalShow,
		},
		{
			name: 'CB Transaction',
			description: 'Last updated on ',
			data1: formatDate(accountMappingData?.cbTransactionDate),
			path: `/menu/outlet-app/account-journals/cb-transaction`,
			permission: PosPermission.AccountJournalsCbTransactionShow,
		},
	];
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	// Drawer Toggler
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	useEffect(() => {
		if (getAssignedOutletByUser && getAssignedOutletByUser?.length > 0) {
			setOriginalListing(getAssignedOutletByUser);
		}
	}, [getAssignedOutletByUser]);

	return (
		<>
			{getAssignedOutletByUserLoading && <Loading />}

			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				sideBtn="switch"
				onClickAction={() => setOpenSwitch(true)}
				routeSegments={[{ name: 'Account Journals', current: true }]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{menuList?.map((v, i) => {
						if (
							handlePermHidden({
								outletID: outletID,
								permEnum: v.permission,
							})
						)
							return (
								<ListItem onClick={() => history.push(v.path)}>
									{/* <SettingsIcon color="primary" style={{ paddingRight: '12px' }} /> */}
									{/* <ListItemIcon>
								<img
									className="icon-svg"
									style={{ paddingRight: '10px' }}
									src={v.icon}
									alt="drawer-icon"
								/>
							</ListItemIcon> */}
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">{v?.name}</span>
											</>
										}
										secondary={
											<>
												<img
													style={{ width: '14px', marginRight: '4px' }}
													src={Auditor}
													alt="Auditor"
												/>
												<span className="desc">
													<span className="desc">{v.description}</span>
													<span className="highlight-text">{v.data1}</span>
												</span>
											</>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete">
											<KeyboardArrowRight
												onClick={() => history.push(v.path)}
											/>
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							);
					})}
				</List>
			</ContentWrapper>

			<CommonDialog
				fullWidth={true}
				open={openSwitch}
				onClose={() => setOpenSwitch(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div
											className="title"
											style={{ color: '#ff9800' }}
										>{`Switch Outlet`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{getAssignedOutletByUser?.length > 2 ? (
								<div className="rm-padding table-wrap sample">
									<List
										className="core-list content-wrap full"
										style={{ paddingBottom: '0px' }}
									>
										<ListItem
											style={{
												boxShadow: 'none',
												padding: '8px 12px 0px 12px',
											}}
										>
											<TextField
												// className="m-b-20"
												id="standard-basic"
												label="Search here..."
												fullWidth
												// value={`${filteredList?.name ?? 0}`}
												onChange={e => {
													handleSearch(`'"${e?.target?.value}"`, ['name']);
												}}
											/>
										</ListItem>
									</List>
								</div>
							) : null}
							<ContentWrapper
								style={{
									marginTop: '0px',
									width: 'inherit',
									position: 'initial',
									padding: '8px 0px 8px 0px',
								}}
							>
								<List>
									{filteredList?.map((outlet, index) => (
										<>
											<ListItem
												style={{
													backgroundColor:
														outlet?.ID === outletID ? '#E5EDFF' : null,
												}}
											>
												<div style={{ paddingRight: '6px' }}>
													{outlet?.logo?.fileURL ? (
														<img
															src={outlet?.logo?.fileURL}
															style={{
																display: 'flex',
																marginTop: '2px',
																marginBottom: '2px',
																marginRight: '4px',
																width: '40px',
																height: '40px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													) : (
														<img
															src={OutletIcon}
															style={{
																display: 'flex',
																marginTop: '7px',
																marginBottom: '8px',
																marginRight: '4px',
																width: '40px',
																height: '25px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													)}
												</div>
												<ListItemText
													primary={
														<span className="xsTitle">{outlet?.name}</span>
													}
													onClick={() => {
														history.push(`/menu/outlet-app/account-Journals`);

														setOpenSwitch(false);
														localStorage.setItem('latestOutlet', outlet?.ID);
														// loadCurrentMealPeriod({
														// 	variables: { outletID: outlet?.ID },
														// });
													}}
												/>
												{outlet?.ID === outletID ? (
													<ListItemSecondaryAction>
														<IconButton edge="end" aria-label="delete">
															<Check color="primary" />
														</IconButton>
													</ListItemSecondaryAction>
												) : null}
											</ListItem>
											<Divider
												// variant="fullWidth"
												style={{ display: 'flex', width: '100%' }}
											/>
										</>
									))}
								</List>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => setOpenSwitch(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
